// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, TextField } from "@mui/material";

// Functional Imports
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Colors from "../../utils/Colors";

// Component Imports
import TimesheetTile from "./TimesheetTile";
import EmptyList from "../../common/EmptyList";
import Loader from "../../common/Loader";

interface TimesheetProps {
  month?: any;
  setMonth?: any;
  weeklyData?: any;
  setWeeklyData?: any;
  dataLoading?: any;
  setDataLoading?: any;
  projectStartDate?: any;
  maxMonth?: any;

  isTesting?: boolean;
}

const Timesheet: FC<TimesheetProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      handleTimesheetExpandAndCollapse(0);
    }
  }, []);

  const handleTimesheetExpandAndCollapse = (index: number) => {
    let weeklyDataPass: any = props.weeklyData;

    weeklyDataPass[index].expanded = !weeklyDataPass[index].expanded;

    props.setWeeklyData([...weeklyDataPass]);
  };

  return (
    <Box
      data-testid="timesheet"
      sx={{
        width: "95%",
        ml: 4,
        mb: 4,
      }}
    >
      <Box
        sx={{
          height: 60,
          pl: 2,
          pt: 0.5,
          width: "100%",
          backgroundColor: Colors.Grey15,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            minDate={dayjs(props.projectStartDate)}
            maxDate={dayjs(props.maxMonth)}
            format="MMM, YYYY"
            views={["year", "month"]}
            name="startDate"
            value={props.month}
            onAccept={(e: any) => props.setMonth(e)}
            slotProps={{
              textField: {
                inputProps: {
                  style: {
                    backgroundColor: Colors.White,
                  },
                },
                variant: "outlined",
                size: "small",
                sx: {
                  width: 160,
                  margin: "6px 8px 8px 0px",
                },
              },
              field: {
                readOnly: true,
              },
              desktopPaper: {
                sx: {
                  height: 280,
                  overflowY: "hidden",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
      {props.dataLoading && <Loader />}
      {!props.dataLoading &&
        props.weeklyData?.map((item: any, index: number) => (
          <TimesheetTile
            key={item.weekStart}
            tableData={item.data}
            isExpanded={item.expanded}
            weekStart={item.weekStart}
            weekEnd={item.weekEnd}
            totalHours={item.totalHoursOfWeek}
            onChevronClick={() => handleTimesheetExpandAndCollapse(index)}
          />
        ))}
      {!props.dataLoading && props.weeklyData?.length === 0 && (
        <EmptyList
          sx={{
            pb: 8,
          }}
        />
      )}
    </Box>
  );
};

export default Timesheet;
