// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, Paper } from "@mui/material";

// Functional Imports
import { object, ref, string } from "yup";
import { useFormik } from "formik";
import API from "../../api/API";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import STRINGS from "../../utils/Strings";
import Functions from "../../utils/Functions";
import CONSTANTS from "../../utils/Constants";

// Component Imports
import PasswordValidationTile from "../../common/PasswordValidationTile";
import PasswordInput from "../../common/PasswordInput";
import ButtonFilled from "../../common/ButtonFilled";
import Text from "../../common/Text";

interface ChangePasswordProps {
  isTesting?: boolean;
}

const ChangePassword: FC<ChangePasswordProps> = (props) => {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    enableReinitialize: true,
    validationSchema: object({
      oldPassword: string().required("This field is required"),
      newPassword: string()
        .matches(
          CONSTANTS.REGEX.PASSWORD2,
          "Password must meet validation criteria"
        )
        .required("This field is required"),
      confirmPassword: string()
        .oneOf([ref("newPassword")], "Passwords must match.")
        .required("This field is required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      handleSubmit({});
    }
  }, []);

  const handleSubmit = (values: any) => {
    API.changePassword({
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    })
      .then((response) => {
        if (response.status === 200) {
          Toasts.success("Password changed successfully. Please log in again.");
          API.logout();
        } else {
          Toasts.error("Incorrect password. Please try again.");
        }
      })
      .catch((e) => {
        Toasts.error("Incorrect password. Please try again.");
      });
  };

  const emailId = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.EMAIL_ID
  );

  return (
    <Box
      data-testid="change-password-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          width: 500,
          height: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 2,
          pb: 3,
          mr: 8,
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          Change Password
        </Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: String(emailId).length > 20 ? "column" : null,
            gap: 0.5,
            mt: 2,
          }}
        >
          <Text fontSize={16}>Change your password for</Text>
          <Text fontWeight={700} fontSize={16}>
            {emailId}
          </Text>
        </Box>

        <PasswordInput
          textFieldSx={{
            width: 380,
            mt: 0.5,
          }}
          sx={{
            mt: 3,
          }}
          label="Current Password"
          placeholder="Enter Current Password"
          required
          textFieldProps={{
            id: "oldPassword",
            name: "oldPassword",
            value: formik.values.oldPassword,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: Functions.Sonar.booleanAnd(
              formik.touched?.oldPassword,
              Boolean(formik.errors?.oldPassword)
            ),
            helperText: Functions.Sonar.booleanAndString(
              formik.touched?.oldPassword,
              formik.errors?.oldPassword
            ),
          }}
        />
        <PasswordInput
          textFieldSx={{
            width: 380,
            mt: 0.5,
          }}
          sx={{
            mt: 2,
          }}
          label="New Password"
          placeholder="Enter New Password"
          required
          textFieldProps={{
            id: "newPassword",
            name: "newPassword",
            value: formik.values.newPassword,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: Functions.Sonar.booleanAnd(
              formik.touched?.newPassword,
              Boolean(formik.errors?.newPassword)
            ),
            helperText: Functions.Sonar.booleanAndString(
              formik.touched?.newPassword,
              formik.errors?.newPassword
            ),
          }}
        />
        <PasswordInput
          textFieldSx={{
            width: 380,
            mt: 0.5,
          }}
          sx={{
            mt: 2,
            mb: 2,
          }}
          label="Confirm Password"
          placeholder="Confirm Password"
          required
          textFieldProps={{
            id: "confirmPassword",
            name: "confirmPassword",
            value: formik.values.confirmPassword,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: Functions.Sonar.booleanAnd(
              formik.touched?.confirmPassword,
              Boolean(formik.errors?.confirmPassword)
            ),
            helperText: Functions.Sonar.booleanAndString(
              formik.touched?.confirmPassword,
              formik.errors?.confirmPassword
            ),
          }}
        />

        <Box
          sx={{
            width: 380,
            flexDirection: "column",
            display: "flex",
            gap: 1,
          }}
        >
          <PasswordValidationTile
            isValid={CONSTANTS.REGEX.MINIMUM_8_CHARACTER_MAXIMUM_16_CHARACTER.test(
              formik.values.newPassword
            )}
            title={STRINGS.CREATE_PASSWORD.MAX_MIN}
          />
          <PasswordValidationTile
            isValid={CONSTANTS.REGEX.ATLEAST_ONE_UPPERCASE_AND_ONE_LOWERCASE.test(
              formik.values.newPassword
            )}
            title={STRINGS.CREATE_PASSWORD.ONE_UPPER_ONE_LOWER}
          />
          <PasswordValidationTile
            isValid={CONSTANTS.REGEX.SPECIAL_CHARACTER.test(
              formik.values.newPassword
            )}
            title={STRINGS.CREATE_PASSWORD.ONE_SPECIAL_CHARACTER}
          />
          <PasswordValidationTile
            isValid={CONSTANTS.REGEX.AT_LEAST_1_NUMBER.test(
              formik.values.newPassword
            )}
            title={STRINGS.CREATE_PASSWORD.ONE_NUMERIC_CHARACTER}
          />
        </Box>
        <ButtonFilled
          testId="change-password-btn"
          sx={{
            width: 170,
            mt: 4,
          }}
          title={"Change Password"}
          onClick={formik.handleSubmit}
        />
      </Paper>
    </Box>
  );
};

export default ChangePassword;
