// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import TimesheetTitle from "./TimesheetTitle";
import TimesheetList from "./TimesheetList";

interface TimesheetTileProps {
  isExpanded?: boolean;
  tableData?: any;
  weekStart?: string;
  weekEnd?: string;
  totalHours?: string;
  onChevronClick?: any;
}

const TimesheetTile: FC<TimesheetTileProps> = (props) => {
  return (
    <Box
      sx={{
        mb: 1,
        backgroundColor: Colors.White,
      }}
    >
      <TimesheetTitle
        isExpanded={props.isExpanded}
        weekStart={props.weekStart}
        weekEnd={props.weekEnd}
        totalHours={props.totalHours}
        onClick={props.onChevronClick}
      />
      <TimesheetList isExpanded={props.isExpanded} data={props.tableData} />
    </Box>
  );
};

export default TimesheetTile;
